<script setup lang="ts">
import { cookiesAccess } from '@/shared/lib/cookiesAccess'
import { ButtonRegular, ButtonWhite } from '@/shared/ui/button'

const $modal = useModal()
const { $analytics } = useNuxtApp()

const active = ref(false)

onMounted(() => {
  $modal.onOpen('cookies-access-tollbar', () => (active.value = true))
  $modal.onClose('cookies-access-tollbar', () => (active.value = false))
})

onBeforeUnmount(() => {
  $modal.off('cookies-access-tollbar', 'open')
  $modal.off('cookies-access-tollbar', 'close')
})

function onCustomize() {
  $modal.open('cookies-access-modal')
}

function onAcceptAll() {
  cookiesAccess.acceptAll()
  $analytics.init()
  active.value = false
}
</script>

<template>
  <div
    v-if="active"
    class="rounded-12 border-1 border-gray-300 lg:w-800 fixed bottom-8 left-16 right-16 z-[5] mx-auto border-solid bg-white px-16 pb-12 pt-20 lg:bottom-24 lg:flex lg:p-8"
    style="
      box-shadow:
        0 0 2px rgba(0, 0, 0, 0.08),
        0 0 24px rgba(0, 0, 0, 0.16);
    "
  >
    <div class="mb-16 flex items-start lg:mb-0 lg:items-center">
      <img
        width="28"
        height="28"
        alt="Cookies icon"
        class="mr-[10px] shrink-0 grow-0"
        src="./toolbar-icon.svg?url"
      />
      <div class="text-h7-regular shrink grow" data-test="widget_cookies__title">
        We use cookies to enhance your browsing experience, serve personalized ads or content, and
        analyze our traffic. By clicking “Accept All”, you consent to our use of cookies.
      </div>
    </div>
    <div class="flex shrink-0 grow-0 justify-between lg:justify-end">
      <ButtonWhite
        size="32"
        class="mr-[10px] w-1/2 px-[10px] lg:shrink lg:grow"
        data-test="widget_cookies__button_customize"
        @click="onCustomize()"
      >
        Customize
      </ButtonWhite>
      <ButtonRegular
        size="32"
        class="w-1/2 bg-[#72c1f9] px-[10px] text-white lg:shrink lg:grow"
        data-test="widget_cookies__button_accept"
        @click="onAcceptAll()"
      >
        Accept all
      </ButtonRegular>
    </div>
  </div>
</template>
